
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersProfileNavigationVue from "@/components/coach/players/profile/navigation.vue";
import CoachPlayersProfileNotesListIndexVue from "@/views/app/coach/players/profile/notes/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersProfileNavigationVue,
    CoachPlayersProfileNotesListIndexVue
  },
})
export default class CoachPlayersProfileNotesIndexVue extends Vue {
}
